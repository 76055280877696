export default [
  /* */
  { name: '交通商圈', imgSrc: '', subTitle: '', section: 'section2', svgSrc: '',offset:0, mobileOffset: 0 , type: '' },
  { name: '居住環境', imgSrc: '', subTitle: '', section: 'section3', svgSrc: '',offset:0, mobileOffset: 0, type: '' },
  { name: '建築外觀', imgSrc: '', subTitle: '', section: 'section4', svgSrc: '',offset:0, mobileOffset: 0, type: '' },
  { name: '安全規劃', imgSrc: '', subTitle: '', section: 'section5', svgSrc: '',offset:0, mobileOffset: 0 , type: '' },
  { name: '智能居家', imgSrc: '', subTitle: '', section: 'section6', svgSrc: '',offset:0, mobileOffset: 0, type: '' },
  { name: '精品配備', imgSrc: '', subTitle: '', section: 'section7', svgSrc: '',offset:0, mobileOffset: 0, type: '' },
 
  { name: '立即來電', imgSrc: '', subTitle: '', section: 'contact-info', svgSrc: '',offset:0, mobileOffset: 0, type: 'btn' },
  { name: '地圖導航', imgSrc: '', subTitle: '', section: 'google-map', svgSrc: '',offset:0, mobileOffset: 0, type: 'btn' },
  { name: '預約賞屋', imgSrc: '', subTitle: '', section: 'contact', svgSrc: '',offset:0, mobileOffset: 0, type: 'btn' },
]